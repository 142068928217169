import { ChakraProvider, ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { extendTheme } from "@chakra-ui/react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import PrivacyPolicy from "./Privacy"



const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)


const colors = {
  brand: {
    50: "#E6F0EB",
    100: "#B3D2C3",
    200: "#9AC3AF",
    300: "#67A587",
    400: "#35875F",
    500: "#026937",
    600: "#02542C",
  },
};

const theme = extendTheme({ colors });
const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy/>,
  },
]);

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
    <RouterProvider router={router} />
    </ChakraProvider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

