import { useThree, Canvas } from '@react-three/fiber'
import {  useState, useEffect, Suspense, useRef } from "react"
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Environment } from '@react-three/drei';
import { TruckModel } from "./TruckModel";


import {
  Container,
} from "@chakra-ui/react";



const CamControls = ({ isOrbitEnabled }) => {

    const {camera, gl} = useThree()
    const controlsRef = useRef();

    useEffect(() => {
  
      const controls = new OrbitControls(camera, gl.domElement)
      controls.enablePan = false;
      controls.enableZoom = false;
      controlsRef.current = controls;

      
      return(()=> {
        controls.dispose()
      })
  
    },[camera,gl])
  
    useEffect(() => {
      if (controlsRef.current) {
        controlsRef.current.enabled = isOrbitEnabled; 
      }
    }, [isOrbitEnabled]);


      return null;
  }

  
  export default function Truck3D(){

    const [truckScale, setTruckScale] = useState(1);
    const [positionX, setPositionX] = useState(400)
    const [isOrbitEnabled, setIsOrbitEnabled] = useState(true);


    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 650 && window.innerWidth > 450) {
          setTruckScale(0.9);
          setPositionX(320);
          setIsOrbitEnabled(false); 

        } else if (window.innerWidth <= 450 && window.innerWidth > 400) {
          setTruckScale(0.8);
          setPositionX(300)
          setIsOrbitEnabled(false);

        } else if(window.innerWidth <= 400 && window.innerWidth > 350){
          setTruckScale(0.75);
          setPositionX(270)
          setIsOrbitEnabled(false);
          
        } else if(window.innerWidth <= 350){
          setTruckScale(0.6);
          setPositionX(225);
          setIsOrbitEnabled(false);

         } else {
          setTruckScale(1);
          setIsOrbitEnabled(true); 
        }
      };
  
      // Initial setup
      handleResize();
  
      // Event listener for window resize
      window.addEventListener("resize", handleResize);
  
      // Cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []);

  

    return(
      
        <Canvas
          

          camera={
            {position : [50 , 100 , 500]}
          }
           >
            <CamControls isOrbitEnabled={isOrbitEnabled} />
      
            
            <ambientLight intensity={1} />
            <directionalLight position={[5, 5, 5]} intensity={1} />
            
            <Suspense fallback={null}>
              <Environment preset="sunset" />
              
            </Suspense>
      
      
            {
              <TruckModel rotation={[0, 1.66, 0]}  position={[ positionX, -250, 0]} scale={truckScale} />
      
            }
      
           
             
           </Canvas>
           
    )
  }