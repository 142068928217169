import { Children, ReactNode } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
  Button,
  Image,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import SmartClean from "./../assets/SmartClean.png";
import SmartReclam from "./../assets/SmartReclam.png";
import SmartExchange from "./../assets/SmartExchange.png";

const content = [
  {
    logo: (
      <Stack alignSelf={"self-start"} marginBottom={"15px"}>
        <Image src={SmartClean} width={"120px"} />
      </Stack>
    ),
    text: "Application pour optimiser la gestion de déchets et simplifier les opérations, permettant une réduction significative des coûts liés à la gestion des parcs, ainsi qu'une meilleure analyse des données pour des décisions plus éclairées.",
    link: "https://app.smartclean.ma/",
    labelButton: "Se connecter",
  },
  {
    logo: (
      <Stack
        alignSelf={"self-start"}
        marginBottom={"15px"}
        display={"flex"}
        direction={"row"}
      >
        <Image src={SmartReclam} width={"40px"} />
        <Text
          margin={"5px"}
          textAlign={"center"}
          fontSize={"xl"}
          fontWeight={"bold"}
        >
          SmartReclam
        </Text>
      </Stack>
    ),
    text: "Application mobile dont l'objectif principal de SmartReclam est de fournir une plateforme conviviale permettant aux utilisateurs de soumettre et de gérer leurs réclamations de manière efficace et intelligente.",
    link: "https://play.google.com/store/apps/details?id=com.smart_reclam.test1",
    labelButton: "Télécharger",
  },
  {
    logo: (
      <Stack
        alignSelf={"self-start"}
        marginBottom={"15px"}
        display={"flex"}
        direction={"row"}
      >
        <Image src={SmartExchange} width={"40px"} />
        <Text
          margin={"5px"}
          textAlign={"center"}
          fontSize={"xl"}
          fontWeight={"bold"}
        >
          SmartExchange
        </Text>
      </Stack>
    ),
    text: "SmartExchange facilite l'échange de documents entre utilisateurs. Avec une interface intuitive et conviviale, elle offre une expérience utilisateur fluide, spécialement conçue pour le secteur de la gestion des déchets.",
    link: "https://smart-exchange-1.web.app/",
    labelButton: "Se connecter",
  },
];

const ProductContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
    >
      {children}
    </Stack>
  );
};

const Product = ({ ...props }) => {
  return (
    <Box flex={1}>
      <ProductContent>
        {props.logo}
        <ProductText>{props.text}</ProductText>
        <Stack
          direction="column"
          justify="center"
          align="center"
          marginTop={"15px"}
        >
          <Link target="_blank" to={props.link}>
            <Button rounded={"60px"} colorScheme="teal" variant="outline">
              {props.labelButton}
            </Button>
          </Link>
        </Stack>
      </ProductContent>
    </Box>
  );
};

const ProductHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading
      as={"h3"}
      fontSize={"4xl"}
      marginBottom={"30px"}
      textAlign="center"
    >
      {children}
    </Heading>
  );
};

const ProductText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      textAlign={"start"}
      color={useColorModeValue("gray.600", "gray.400")}
      fontSize={"sm"}
    >
      {children}
    </Text>
  );
};

export default function Products() {
  return (
    <Box bg={useColorModeValue("gray.100", "gray.700")}>
      <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={"center"}>
          <ProductHeading>Nos Produits</ProductHeading>
          <Text textAlign={"center"}>
            Découvrez nos différent produits dans la gestion de déchets
          </Text>
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          {content.map((p) => {
            return (
              <Product
                logo={p.logo}
                text={p.text}
                link={p.link}
                labelButton={p.labelButton}
              />
            );
          })}
        </Stack>
      </Container>
    </Box>
  );
}
