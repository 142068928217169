import { Children, ReactNode } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
  Button,
  Image
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';


import partner1 from "./../assets//partner1.png"
import partner2 from "./../assets/partner2.png"
import partner3 from "./../assets/partner3.png"




const content = [
    {logo:<Image src={partner1} />},
    {logo:<Image src={partner2} />},
    {logo:<Image src={partner3} />}

]







const PartnerContent = ({ children }: { children: ReactNode }) => {
    return (
      <Stack
        bg={useColorModeValue('white', 'gray.800')}
        p={8}
        rounded={'xl'}
        align={'center'}
        pos={'relative'}
        >
        {children}
      </Stack>
    );
  };

  
const Partner = ({...props}) => {

    return(
        <Box flex={1}>
            <PartnerContent>
                {props.logo}
            </PartnerContent>  
        </Box>
    )

}


const PartnerHeading = ({ children }: { children: ReactNode }) => {
    return (
      <Heading as={'h3'} fontSize={'4xl'} marginBottom={"30px"} textAlign="center">
        {children}
      </Heading>
    );
  };



const PartnerText = ({ children }: { children: ReactNode }) => {
    return (
      <Text
        textAlign={'start'}
        color={useColorModeValue('gray.600', 'gray.400')}
        fontSize={'sm'}
        
        >
        {children}
      </Text>
    );
  };


export default function Partners(){


  return(
    <Box bg={useColorModeValue('white', 'white.700')}>
    <Container maxW={'4xl'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
            <PartnerHeading>Ils nous font confiance</PartnerHeading>
            
        </Stack>
        <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: 10, md: 4, lg: 10 }}>
                
                    {content.map( (p) => {
                        return(
                            <Partner logo={p.logo} />
                        )
                    })} 
                
           
        </Stack>
    </Container>
   </Box>
  )
}