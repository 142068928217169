/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: iedalton (https://sketchfab.com/iedalton)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/garbage-truck-abc7705b46bd4e9fa7a245ea15f9d89a
Title: Garbage Truck
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function TruckModel(props) {
  const { nodes, materials } = useGLTF('./garbage_truck.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0, 325.001, -464.269]} rotation={[-Math.PI / 2, 0, 0]} scale={80}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023_MainBody_0.geometry}
          material={materials.MainBody}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023_Cab_0.geometry}
          material={materials.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023_Material001_0.geometry}
          material={materials['Material.001']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023_Black_0.geometry}
          material={materials.Black}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023_Ligght_0.geometry}
          material={materials.Ligght}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023_Blinker_0.geometry}
          material={materials.Blinker}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023_Silver_0.geometry}
          material={materials.Silver}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023_tire_0.geometry}
          material={materials.tire}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023_tail_0.geometry}
          material={materials.tail}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube023_Outline_0.geometry}
          material={materials.Outline}
        />
      </group>
    </group>
  )
}

useGLTF.preload('./garbage_truck.glb')
