
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import Testimonials from './components/Testimonials'
import Contact from './components/Contact'
import Footer from './components/Footer'
import Products from './components/Products'
import Partners from './components/Partners'

export const App = () => (
    <>
      <Navbar />
      <Hero /> 
      <Products />
      <Partners />
      <Testimonials />
      <Contact />
      <Footer />
    </>
)
