import {
    Box,
    Container,
    Stack,
    Text,
    useColorModeValue,
    Spacer,
    Image,
    Link
  } from '@chakra-ui/react';

  import logo from '../assets/logo.png';
  
  export default function SmallWithLogoLeft() {
    return (
      <Box
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          <Image src={logo} h={3}/>
          <Text>© 2023 Smartclean. Tous droits réservés.</Text>
            <Spacer/>
            <Link href='/privacy' target='blank' textDecoration={'underline'}>Politiques de confidentialité</Link>
        </Container>
      </Box>
    );
  }