import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  IconProps,
  Icon,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import abstractGreenGradient from "../assets/abstract-green-gradient.png";
import customerServiceCall from "../assets/customer-service-call.jpeg";
import sendmessage from "../assets/send-message.png";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

const avatars = [
  {
    name: "Ryan Florence",
    url: "https://bit.ly/ryan-florence",
  },
  {
    name: "Segun Adebayo",
    url: "https://bit.ly/sage-adebayo",
  },
  {
    name: "Kent Dodds",
    url: "https://bit.ly/kent-c-dodds",
  },
  {
    name: "Prosper Otemuyiwa",
    url: "https://bit.ly/prosper-baba",
  },
  {
    name: "Christian Nwamba",
    url: "https://bit.ly/code-beast",
  },
];

export default function JoinOurTeam() {

  const formRef = useRef<HTMLFormElement>(null);
  
  const notify1 = () => Swal.fire({
    icon: "success",
    title: "Succès",
    text: "Votre message a été envoyé avec succès",
    showConfirmButton: false,
    timer: 2500,
    showCloseButton: true
  });

  const notify2 = () => Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Un problème est survenu",
    showConfirmButton: false,
    timer: 3000,
    showCloseButton: true
  });

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formRef.current) {
      emailjs
        .sendForm('service_djy4cqo', 'template_hto5vkm', formRef.current, {
          publicKey: 'gGZp6XqEP0Sh4-o7q',
        })
        .then(
          () => {
            console.log('SUCCESS!');
            notify1();
            if (formRef.current) {
              formRef.current.reset();
            }
          },
          (error) => {
            console.log('FAILED...', error.text);
            notify2();
            if (formRef.current) {
              formRef.current.reset();
            }
          },
        );
    }
  };

  return (

    <Box margin="20px" rounded={"18px"} position={"relative"} backgroundImage={`url(${abstractGreenGradient})`} backgroundSize="cover" backgroundPosition="center" padding={{ md: "40px", base: "16px" }} overflow='hidden'>
      <ToastContainer />
      <Flex alignItems="center" gap={10}>
        <Flex flex="1" justify="center" align="center" width="100%">
          <Stack spacing={5} bgColor="rgba(255, 255, 255, 0.9)" rounded={"xl"} padding={{ sm: "40px", base: "16px" }} w="full" zIndex={10}>
            <Heading lineHeight={1.1} fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "6xl" }} textAlign="center">
              Contactez-nous!
            </Heading>
            <Flex direction="column" gap={4} w="full" fontSize={{ base: "lg", sm: "2xl", md: "2xl" }} margin={{ md: "auto" }} justifyContent="center">
              <form ref={formRef} onSubmit={sendEmail}>
                <Stack spacing={4}>
                  <Input
                    placeholder="Email*"
                    type="email"
                    borderColor="green.500"
                    fontSize="lg"
                    padding="6"
                    height="50px"
                    width="100%"
                    isRequired
                    name="user_email"
                  />
                  <Input
                    placeholder="Téléphone*"
                    type="tel"
                    borderColor="green.500"
                    fontSize="lg"
                    padding="6"
                    height="50px"
                    width="100%"
                    isRequired
                    name="user_phone"
                  />
                  <Input
                    placeholder="Message*"
                    as="textarea"
                    rows={8}
                    borderColor="green.500"
                    fontSize="lg"
                    padding="6"
                    height="200px"
                    width="100%"
                    resize="none"
                    isRequired
                    name="message"
                  />
                  <Button
                    colorScheme="green"
                    type="submit"
                    fontSize="lg"
                    padding="6"
                    height="50px"
                    _hover={{
                      bg: "green.600"
                    }}
                  >
                    Envoyer
                  </Button>
                </Stack>
              </form>
            </Flex>
          </Stack>
          <Box
            position="absolute"
            top="30%"
            left="25%"
            transform="translate(-50%, -10%)"
            width="70%"
            height="70%"
            backgroundImage={`url(${sendmessage})`}
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            opacity={1.5}
            zIndex={0}
          />
        </Flex>
        <Flex flex="1" align="center" justify="center" display={{ base: "none", lg: "flex" }}>
          <Image
            src={customerServiceCall}
            alt="Customer Service Call"
            borderRadius="lg"
            boxShadow="lg"
            width="100%"
            height="580px"
            objectFit="cover"
          />
        </Flex>
      </Flex>

    </Box>
);}

export const Blur = (props: IconProps) => {
  return (
    <Icon
      width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>

  );
};

