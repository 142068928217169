
import {
    Box,
    Heading,
    ListItem,
    Text,
    UnorderedList,
  } from "@chakra-ui/react";
import Navbar from "./components/Navbar";
  
  const PrivacyPolicy = () => {
    return ( <Box maxW="800px" mx="auto" p="6">
      <Heading as="h1" mb="4">
        PRIVACY POLICY
      </Heading>
      <Text mb="4">Last updated January 23, 2024</Text>

      <Text>
        This privacy notice for Intergrale E&F ("we," "us," or "our"), describes how and why we might collect,
        store, use, and/or share ("process") your information when you use our services ("Services"), such as when you:
      </Text>

      <Heading as="h1" mt="6" mb="2">
        SUMMARY OF KEY POINTS
      </Heading>
      <UnorderedList>
        <ListItem>What personal information do we process?</ListItem>
        <ListItem>Do we process any sensitive personal information?</ListItem>
        <ListItem>Do we receive any information from third parties?</ListItem>
        <ListItem>How do we process your information?</ListItem>
        <ListItem>In what situations and with which parties do we share personal information?</ListItem>
        <ListItem>What are your rights?</ListItem>
        <ListItem>How do you exercise your rights?</ListItem>
        <ListItem>Want to learn more about what we do with any information we collect?</ListItem>
      </UnorderedList>

      <Heading as="h2" mt="6" mb="2">
        TABLE OF CONTENTS
      </Heading>
      <UnorderedList>
        <ListItem>1. WHAT INFORMATION DO WE COLLECT?</ListItem>
        <ListItem>2. HOW DO WE PROCESS YOUR INFORMATION?</ListItem>
        <ListItem>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</ListItem>
        <ListItem>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</ListItem>
        <ListItem>5. HOW LONG DO WE KEEP YOUR INFORMATION?</ListItem>
        <ListItem>6. DO WE COLLECT INFORMATION FROM MINORS?</ListItem>
        <ListItem>7. WHAT ARE YOUR PRIVACY RIGHTS?</ListItem>
        <ListItem>8. CONTROLS FOR DO-NOT-TRACK FEATURES</ListItem>
        <ListItem>9. DO WE MAKE UPDATES TO THIS NOTICE?</ListItem>
        <ListItem>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</ListItem>
        <ListItem>11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</ListItem>
      </UnorderedList>

      <Heading as="h5" size='xl' mt="6" mb="2">
        1. WHAT INFORMATION DO WE COLLECT?
      </Heading>
      <Text>Personal information you disclose to us

        In Short: We collect personal information that you provide to us.

        We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.

        Sensitive Information. We do not process sensitive information.

        Application Data. If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
        Geolocation Information. We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
        This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.

        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.

      </Text><Heading as="h5" size='xl' mt="6" mb="2">
        2. HOW DO WE PROCESS YOUR INFORMATION?
      </Heading><Text>


        In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.

        We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
        To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.




      </Text><Heading as="h5" size='xl' mt="6" mb="2">
        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </Heading><Text>


        In Short: We may share information in specific situations described in this section and/or with the following third parties.

        We may need to share your personal information in the following situations:
        Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
        When we use Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). We use certain Google Maps Platform APIs to retrieve certain information when you make location-specific requests. This includes: __________; and other similar information. A full list of what we use information for can be found in this section and in the previous section titled "HOW DO WE PROCESS YOUR INFORMATION?" We obtain and store on your device ("cache") your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document. The Google Maps Platform APIs that we use store and access cookies and other information on your devices. If you are a user currently in the European Economic Area (EU countries, Iceland, Liechtenstein, and Norway) or the United Kingdom, please take a look at our Cookie Notice.
      </Text><Heading as="h5" size='xl' mt="6" mb="2">
        4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
      </Heading><Text>


        In Short: We may use cookies and other tracking technologies to collect and store your information.

        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.

      </Text><Heading as="h5" size='xl' mt="6" mb="2">
        5. HOW LONG DO WE KEEP YOUR INFORMATION?
      </Heading><Text>


        In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.

        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than   the period of time in which users have an account with us.

        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
      </Text><Heading as="h5" size='xl' mt="6" mb="2">
        6. DO WE COLLECT INFORMATION FROM MINORS?
      </Heading><Text>


        In Short: We do not knowingly collect data from or market to children under 18 years of age.

        We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at cherratbachir@gmail.com.
      </Text><Heading as="h5" size='xl' mt="6" mb="2">
        7. WHAT ARE YOUR PRIVACY RIGHTS?
      </Heading><Text>



        In Short:  You may review, change, or terminate your account at any time.

        Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.

        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.

        Account Information

        If you would at any time like to review or change the information in your account or terminate your account, you can:
        Contact us using the contact information provided.
        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
      </Text><Heading as="h5" size='xl' mt="6" mb="2">
        8. CONTROLS FOR DO-NOT-TRACK FEATURES
      </Heading><Text>


        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
      </Text><Heading as="h5" size='xl' mt="6" mb="2">
        9. DO WE MAKE UPDATES TO THIS NOTICE?
      </Heading><Text>


        In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.

        We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
      </Text><Heading as="h5" size='xl' mt="6" mb="2">
        10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
      </Heading><Text>


        If you have questions or comments about this notice, you may email us at cherratbachir@gmail.com or contact us by post at:

        Intergrale E&F
        __________
        Casablanca, Morocco 20000
        Morocco
      </Text><Heading as="h5" size='xl' mt="6" mb="2">
        11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </Heading><Text>


        You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.
        </Text>

    </Box>
    );
  };
  
  export default PrivacyPolicy;
